import { FC } from 'react';
import { Icon, HStack } from '@chakra-ui/react';
import {
  FacebookShareButton,
  // HatenaShareButton,
  LineShareButton,
  // PocketShareButton,
  TwitterShareButton
} from 'react-share';
import { FaFacebook, FaLine, FaTwitter } from 'react-icons/fa';
// import { FaFacebook, FaGetPocket, FaLine, FaTwitter } from 'react-icons/fa';
// import { SiHatenabookmark } from 'react-icons/si';

type ShareButtonsProps = {
  title: string;
  shareText: string;
  url: string;
  twitterId: string | undefined;
  hashtags: string[];
};

const SNS: FC<ShareButtonsProps> = (props) => {
  const { title, shareText, url, twitterId, hashtags } = props;
  return (
    <HStack spacing="2" mt="12px">
      <TwitterShareButton
        url={url}
        title={`${shareText} - ${title}`}
        via={twitterId}
        hashtags={hashtags}>
        <Icon
          as={FaTwitter}
          boxSize={10}
          fill="gray.400"
          _hover={{ fill: 'green.600', borderColor: 'green.600' }}
          transition="all 300ms ease-in"
          border="4px solid"
          borderColor="gray.400"
          borderRadius="8px"
          p="4px"
        />
      </TwitterShareButton>
      <FacebookShareButton url={url}>
        <Icon
          as={FaFacebook}
          boxSize={10}
          fill="gray.400"
          _hover={{ fill: 'green.600', borderColor: 'green.600' }}
          transition="all 300ms ease-in"
          border="4px solid"
          borderColor="gray.400"
          borderRadius="8px"
          p="4px"
        />
      </FacebookShareButton>
      <LineShareButton title={`${shareText} - ${title}`} url={url}>
        <Icon
          as={FaLine}
          boxSize={10}
          fill="gray.400"
          _hover={{ fill: 'green.600', borderColor: 'green.600' }}
          transition="all 300ms ease-in"
          border="4px solid"
          borderColor="gray.400"
          borderRadius="8px"
          p="4px"
        />
      </LineShareButton>
    </HStack>
  );
};

export default SNS;
