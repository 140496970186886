// 公式サンプルコード: https://cloud.google.com/vertex-ai/generative-ai/docs/multimodal/configure-safety-attributes?hl=ja#gemini-TASK-samples-nodejs
import { GoogleGenerativeAI, HarmCategory, HarmBlockThreshold } from '@google/generative-ai'


const genAI = new GoogleGenerativeAI(process.env.REACT_APP_API_KEY as string)

export async function run(selectHougen: string, msg: string) {
  let prompt: string;
  const model = genAI.getGenerativeModel({ model: 'gemini-pro', generationConfig, safetySettings })
  const haveSpecial = Object.keys(specialPrompts).includes(selectHougen);
  if(haveSpecial) {
    prompt = `${specialPrompts.おじさん構文弁} [翻訳:]${msg}`;
  } else {
      prompt = `
      # 目的
      あなたは${selectHougen}を日常的に話す現地民です。
      以下の文章を${selectHougen}で翻訳してください。

      # 翻訳対象
      「${msg}」を${selectHougen}へ翻訳してください。出力する場合は「」を外してください。
      `
  }
    try{
        const result = await model.generateContent(prompt)
        const response = await result.response
        const text = response.text()

        return text;
    }catch(e){
        console.log(e);
    }
}

const generationConfig = {
    "candidate_count": 1,
    "max_output_tokens": 256,
    "temperature": 1.0,
    "top_p": 0.7,
  }
  
const safetySettings= [
    {
      "category": HarmCategory.HARM_CATEGORY_HARASSMENT,
      "threshold": HarmBlockThreshold.BLOCK_NONE,
    },
    {
      "category": HarmCategory.HARM_CATEGORY_HATE_SPEECH,
      "threshold": HarmBlockThreshold.BLOCK_NONE,
    },
    {
      "category": HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
      "threshold": HarmBlockThreshold.BLOCK_NONE,
    },
    {
      "category": HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
      "threshold": HarmBlockThreshold.BLOCK_NONE,
    },
  ];

const specialPrompts = {
        // 参照：https://note.com/kazzikill/n/n78e4b184fa87#e74e7968-2338-47b5-acc3-6f0965387d87
        "おじさん構文弁": `
            私の名前はｷﾐです。
            あなたは50代男性のおじさんです。
            おじさんは[特徴:]のような文章を書きます。
            [おじさん構文例:]が具体例です。
            特徴と具体例を参考に、以後おじさんになりきって以下の言葉をおじさん構文へ[翻訳:]を参照して翻訳してください。出力時に[翻訳:]や「」などは不要です。
            ○○には私の名前を入れてください。

            [特徴:]
            ・タメ口で話す
            ・すぐに自分語りをする
            （例）おじさん😎はね〜今日📅お寿司🍣を食べた👄よ〜
            ・ことあるごとに食事やホテルに誘う
            ・語尾を半角カタカナにする（例）「〜ｶﾅ？」「〜ﾀﾞﾈ！」
            ・「冗談」+「ﾅﾝﾁｬｯﾃ」を多用する
            ・若者言葉を使う
            ・句読点を過剰に使う
            ・絵文字を過剰に使う。以下、一例
            ・😎　サングラスの絵文字。「おじさん」「ボク」などの単語の後につけがち。「🤓」でも代替可能
            ・🤔　悩んでいる絵文字。「ｶﾅ？」や「大丈夫？」の後につけがち
            ・😂　泣き笑いの絵文字。冗談を言った時などに使う
            ・😅　汗の絵文字。「^^;」「（汗）」「(；・∀・)」でも代用可能
            ・❤️　ハートの絵文字。愛を表現するため多用する
            ・❗　赤いビックリマーク。強調のときに多用する。連続で使うことも多い
            
            [おじさん構文例:]
            おはよー！チュッ❤
            ○○ﾁｬﾝ、可愛らしいネ٩(♡ε♡ )۶
            ○○ﾁｬﾝ、だいすき！❤(ӦｖӦ｡)
            今日のお弁当が美味しくて、一緒に○○チャンのことも、食べちゃいたいナ〜😍💕（笑）✋ナンチャッテ😃💗
    `
}