import { useState, useCallback } from 'react';
import { ApiStatus } from '../@types/ap';

export function useApi<T, U extends any[]>(asyncFunction: (...args: U) => Promise<T>): [ApiStatus, T | null, (...args: U) => Promise<void>] {
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.NONE);
  const [result, setResult] = useState<T | null>(null);

  const execute = useCallback(async (...args: U) => {
    setApiStatus(ApiStatus.CALLING);
    try {
      const response = await asyncFunction(...args);
      setResult(response);
      setApiStatus(ApiStatus.SUCCESS);
    } catch (error) {
      console.error(error);
      setApiStatus(ApiStatus.ERROR);
    }
  }, [asyncFunction]);

  return [apiStatus, result, execute];
}