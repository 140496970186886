/* eslint-enable */
export enum ApiStatus {
    NONE = "NONE",
    CALLING = "CALLING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}
/* eslint-enable */
export declare interface ShareButtonsProps {
    title: string;
    shareText: string;
    url: string;
    twitterId: string | undefined;
    hashtags: string[];
  }
