import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Heading,
  Textarea,
  Spinner,
  Img,
  Flex,
  LinkBox,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"
import { run } from "./api/chat"
import { HougenSelector } from "./ui/Radio"
import AboutSiteAccordion from "./ui/Accordion"
import { useApi } from './api/useApi';

import SNS from "./ui/SNS"
import { ApiStatus, ShareButtonsProps } from "./@types/ap"
import MyDefaultSEO from "./util/MyDefaultSEO"
import { HelmetProvider } from "react-helmet-async"

import ReactGA from "react-ga4";

export const App = () => {
  const [selectHougen, setSelectHougen] = React.useState('博多弁');
  const [msg, setMsg] = React.useState('');
  const [translatedMsg, setTranslatedMsg] = React.useState('');
  const [apiStatus, result, execute] = useApi(run);
  const MAX_LEN = 140;

  const handleChangeHougen = (value: string) => setSelectHougen(value);
  const handleClickTranslator = async(selectHougen: string, msg: string) => {
    await execute(selectHougen, msg)
  };
  const handleChangeTranslatedMsg = (value: string) => setTranslatedMsg(value);
  const handleChangeMsg = (value: string) => setMsg(value);

  React.useEffect(() => {
    setTranslatedMsg(result ?? '');
  },[result]);

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
  },[])

  const seoSNS: ShareButtonsProps = React.useMemo(() => {
    return {
      title: 'にわか方言翻訳機',
      shareText: translatedMsg ? translatedMsg : '精度のあまり高くないAI翻訳で方言に変換してみよう',
      url: process.env.REACT_APP_ORIGIN ?? 'https://hougen.aminosan.app/',
      twitterId: 'akifumidev',
      hashtags: ['AI', translatedMsg !== '' && apiStatus === ApiStatus.SUCCESS ? selectHougen : '方言']
    };
  },[translatedMsg]);

  return (
      <ChakraProvider theme={theme}>
        <MyDefaultSEO />
        <Box textAlign="center" fontSize="xl">
          <Grid minH="100vh" p={3}>
            <ColorModeSwitcher justifySelf="flex-end" />
            <VStack spacing={8}>
              <Heading as="h1">にわか方言翻訳機</Heading>
              <VStack spacing={2}>
                <Text>にわかな方言に翻訳してくれるサイト</Text>
                <Text fontSize="0.6em" color="gray">例：じゃけん、方言に直しちゃるサイトばい</Text>
              </VStack>
              <Logo h="100px" pointerEvents="none" />
              <HougenSelector onChangeHougen={handleChangeHougen} />
              <Box
                maxW="380px"
                minW="300px"
                pos="relative"
              >
                <Textarea
                  isDisabled={apiStatus === ApiStatus.CALLING}
                  onChange={(e) => handleChangeMsg(e.target.value)}
                  value={msg}
                  h="200px"
                  border="4px"
                  borderColor="gray.500"
                  opacity={apiStatus === ApiStatus.CALLING ? 0.3 : 1}
                  placeholder="例：好きです。付き合ってください"
                ></Textarea>
                {apiStatus === ApiStatus.CALLING && (
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -68%)"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Box>
              )}
                <Box
                  as="span"
                  fontSize="12px"
                  color={msg.length > MAX_LEN ? "red.500" : "gray.500"}
                >
                  残り: {MAX_LEN - msg.length}文字
                </Box>
              </Box>
              <Box
                maxW="380px"
                minW="300px"
              >
              <Textarea
                onChange={(e) => handleChangeTranslatedMsg(e.target.value)}
                value={translatedMsg}
                h="180px"
                border="4px"
                borderColor={apiStatus === ApiStatus.SUCCESS && translatedMsg !== '' ? "green.600" : "gray.500"}
                placeholder="翻訳後"
                transition="ease-in 200ms"
              ></Textarea>
              </Box>
              <Button
                onClick={() => handleClickTranslator(selectHougen, msg)}
                isDisabled={
                  msg === ''
                  || msg.length > MAX_LEN
                  || !selectHougen
                  || apiStatus === ApiStatus.CALLING
                }
                >翻訳する</Button>
              <SNS {...seoSNS}/>
              <AboutSiteAccordion />
              <Box>他に作ったやつ</Box>
              <Flex flexWrap={"wrap"} justifyContent="center">
                <LinkBox onClick={() => window.open('https://100g.jp/')} border="4px solid #e0e0e0" m={2}>
                  <Img src="./images/ad_100g.png" alt="100gのサイト" w="180px" cursor="pointer"/>
                </LinkBox>
                <LinkBox onClick={() => window.open('https://aminosan.app/')} border="4px solid #e0e0e0" m={2}>
                  <Img src="./images/ad_amino.png" alt="aminosanのサイト" w="180px" cursor="pointer"/>
                </LinkBox>
              </Flex>
              <Text>
                <Code fontSize="md">akifumi©︎2024~</Code>
              </Text>
            </VStack>
          </Grid>
        </Box>
      </ChakraProvider>
)
}
