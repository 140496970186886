import { Box, HStack, useRadio, useRadioGroup } from "@chakra-ui/react"

// 1. Create a component that consumes the `useRadio` hook
export function RadioCard(props: any) {
    const { getInputProps, getRadioProps } = useRadio(props)
  
    const input = getInputProps()
    const checkbox = getRadioProps()
  
    return (
      <Box as='label'>
        <input {...input} />
        <Box
          {...checkbox}
          cursor='pointer'
          borderWidth='1px'
          borderRadius='md'
          border="4px"
          borderColor="gray.600"
          boxShadow='md'
          fontSize={{base: '12px', md: '16px'}}
          _hover={{transform: 'scale(1.1) rotate(4deg)'}}
          transition="ease-in 140ms"
          w={{base: '34px', sm: '40px'}}
          p={2}
          _checked={{
            bg: 'green.600',
            color: 'white',
            borderColor: 'teal.600',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
        >
          {props?.children}
        </Box>
      </Box>
    )
  }
  // Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
  export function HougenSelector({onChangeHougen}: {onChangeHougen: (value: string) => void}) {
    const options = ['博多弁', '京都弁', '大阪弁', '北海道弁', '東北弁', '沖縄弁', 'おじさん構文弁']

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: 'framework',
      defaultValue: '博多弁',
      onChange: onChangeHougen,
    })
  
    const group = getRootProps()
  
    return (
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value })
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          )
        })}
      </HStack>
    )
  }