import { Helmet } from 'react-helmet-async';

export const siteName = 'にわか方言翻訳機';

const MyDefaultSEO = (): JSX.Element => {
  const titleTemplate = `${siteName}`;
  const ogImageUrl = ``;

  return (
    <Helmet>
      <title>{titleTemplate}</title>
    </Helmet>
  );
};

export default MyDefaultSEO;