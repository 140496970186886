import React from 'react';
import { Accordion, AccordionItem, AccordionButton, Box, AccordionPanel, AccordionIcon } from '@chakra-ui/react';

export const AboutSiteAccordion = () => {
  return (
    <Accordion allowMultiple maxW="800px" w="100%" borderTop="2px" borderBottom="2px" borderColor="gray.600"> 
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex='1' textAlign='left'>
              このサイトについて
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          このサイトは運営者がAIモデルのAPIを使ってみる実験用に作りました!(Gemini AI使用)
          
          <br/><br/>ざるな翻訳品質、ざるな基準にもとづいてい運営されています!
          <br/><br/>何も期待せずにご利用ください!
          <br/><br/>使えない場合はAPIの無料枠が切れてしまっていると思われます!
          <br/><br/>24年3月現在は1日におきに無料枠がリセットされます(無料枠がなくなり次第終了!)
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AboutSiteAccordion;
